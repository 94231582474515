import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Label } from "theme-ui";

import { Divider, Icon } from "ui";
import Button from "@coninsa-s2/button";
import { getNormalizerServiceType } from "modules/search-building/utils";
import BuildingContactForm from "./BuildingContactForm";

function PropertyInfo({
  canon,
  code,
  path,
  propertyType,
  region,
  serviceType,
  className = "",
}) {
  const [open, setOpen] = useState(false);
  const showForm = () => setOpen(true);

  const [submitted, setSubmitted] = useState(false);

  const phoneAR = `3014726883`;
  const phoneVE = `3014726883`;
  const service = getNormalizerServiceType(serviceType);

  const phone = service === "AR" ? phoneAR : phoneVE;

  const whatsappPath = `https://api.whatsapp.com/send?phone=57${phone}&text=Hola! Los vi en coninsa.co, me gustaría obtener más información. Inmueble: ${code}`;

  const pdfPath = `https://busquedas.coninsa.co/generar-ficha-inmuebles/${code}`;

  return (
    <div className={`${className}`}>
      {submitted && (
        <Box sx={{ px: 5, textAlign: "center" }}>
          <Icon
            name="fa-reg-check-circle"
            sx={{ color: "secondary", fontSize: "47px" }}
          />
          <Label
            sx={{
              display: "block",
              textAlign: "center",
              color: "secondary",
              fontWeight: "semibold",
            }}
          >
            ¡Hemos recibido tus datos!
          </Label>
          <Box sx={{ fontSize: 1 }}>
            Pronto un asesor te llamará para brindarte toda la información sobre
            este inmueble.
          </Box>
        </Box>
      )}

      {!submitted && (
        <div className="bg-white rounded-lg shadow-[0px_3px_6px_#00000029] p-8 sticky top-10">
          <h4 className="font-bold mb-4">¿Quieres arrendar este inmueble?</h4>

          <div className="flex flex-col gap-4">
            {/* <Button href="/estudio-digital" target="_blank">
              <Icon name="fa-reg-file" />
              Iniciar mi estudio digital
            </Button> */}

            <Button inverted onClick={() => setOpen(!open)}>
              <Icon name="fa-calendar" />
              Agendar una cita
            </Button>

            {open && (
              <BuildingContactForm
                code={code}
                canon={canon}
                path={path}
                propertyType={propertyType}
                region={region}
                serviceType={serviceType}
              />
            )}
          </div>

          <Divider my="24px" />
          <div>
            <h4 className="font-bold mb-4">Recibe más información</h4>

            <Button size="fluid" href={whatsappPath} target="_blank" tint="green">
              <Icon name="fa-whatsapp" />
              Contactar a un asesor
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

PropertyInfo.defaultProps = {
  canon: "",
};

PropertyInfo.propTypes = {
  canon: PropTypes.string,
  code: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
};

export default PropertyInfo;
