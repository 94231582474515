import React from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import "react-intl-tel-input-18/dist/main.css";
import parsePhoneNumber from "libphonenumber-js";
import IntlPhone from "@coninsa-s2/intl-phone";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import HabeasData from "../../../react/quotation/src/components/HabeasData";
import Button from "@coninsa-s2/button";

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { phone: "" } });

  const onSubmit = async (data) => {
    // Asignación de Sucursal basado en la ciudad seleccionada
    let sucursal = "";
    if (data.city === "Bogotá") {
      sucursal = "ABR BOGOTA SANTA BARBARA";
    } else if (data.city === "Medellín") {
      sucursal = "ABR MEDELLIN CENTRO";
    } else if (data.city === "Barranquilla") {
      sucursal = "ABR BARRANQUILLA LA 93";
    }
  
    const zohoInfo = {
      data: [
        {
          First_Name: data.first_name,
          Last_Name: data.last_name,
          Owner: "2528071000008997151",
          Email: data.email,
          Tipo_de_Inmueble_de_Inter_s: data.property_type,
          Servicio_de_inter_s_1: data.service_type,
          Origen_de_Informaci_n: "Digital",
          Medio_Publicitario: "Landing preconsignar", // Actualización
          Servicio_interes: "Arriendo", // Opciones Arriendo o Venta
          Mobile: data.phone,
          Valor_canon_de_arrendamiento: parseInt(data.canon),
          Barrio_Ubicacion_del_inmueble: data.neighborhood,
          Sucursal: sucursal, // Sucursal asignada dinámicamente
          Regional: data.city, // Asignar ciudad a Regional
          Creador_API: "www.coninsa.co",
          Tipo_de_Canal: "Digital",
          Tipo_de_Contacto: "Otros",
          Tipo_de_consignaci_n: "Inmueble Nuevo",
          Layout: {
            id: "2528071000000458001",
          },
        },
      ],
    };
  
    const url = "https://api.coninsa.co/api/v2/zohocrm-create-lead";
  
    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then((response) => {
          reset();
          resolve(response);
          window.open("https://www.coninsa.co/gracias");
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  return (
    <Section tint="ligther-gray" ptop="small" id="dejanos-tus-datos">
      <Container>
        <div
          className="items-center justify-center gap-10 lg:flex"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4 font-[100] text-s2-gray-600 lg:w-96 lg:text-3xl">
            <span>Déjanos tus datos</span> para comunicarnos contigo y
            promocionar tu propiedad.
          </div>

          <form className="form-subject-matter lg:w-96">
            <div className="co-form">
              <div className="form-control">
                <input
                  {...register("first_name")}
                  type="text"
                  id="first_name"
                  placeholder="Nombres"
                  required
                />
              </div>
              <div className="form-control">
                <input
                  {...register("last_name")}
                  type="text"
                  id="last_name"
                  placeholder="Apellidos"
                  required
                />
              </div>
              <div className="form-control is-intl-tel">
                <Controller
                  control={control}
                  name="phone"
                  rules={{
                    validate: (value) =>
                      parsePhoneNumber(value)?.isValid() ||
                      "Número de teléfono inválido",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <IntlPhone
                      id="phone"
                      placeholder="Número de contacto"
                      defaultCountry={"co"}
                      onChange={onChange}
                      required
                      value={value}
                    />
                  )}
                />

                {errors?.phone && (
                  <p className="form-error">{errors?.phone?.message}</p>
                )}
              </div>
              <div className="form-control">
                <input
                  {...register("email")}
                  type="email"
                  id="email"
                  placeholder="Correo electrónico"
                  required
                />
              </div>
              <div className="form-control">
                <select
                  className="text-area"
                  {...register("property_type")}
                  id="property_type"
                  required
                >
                  <option value="">Tipo de inmueble</option>
                  <option value="Apartamento">Apartamento</option>
                  <option value="Aparta estudio">Aparta estudio</option>
                  <option value="Casa">Casa</option>
                  <option value="Local">Local</option>
                  <option value="Arriendo">Oficina</option>
                  <option value="Bodega">Bodega</option>
                </select>
              </div>
              <div className="form-control">
                <select
                  className="text-area"
                  {...register("canon")}
                  id="canon"
                  required
                >
                  <option value="">Canon</option>
                  <option value="1000000">De $1.000.000 a $1.500.000</option>
                  <option value="1500000">De $1.500.000 a $2.100.000</option>
                  <option value="2100000">De $2.100.000 a $3.000.000</option>
                  <option value="3000000">Más de $3.000.000</option>
                </select>
              </div>
              <div className="form-control">
                <select
                  className="text-area"
                  {...register("service_type")}
                  id="service_type"
                  required
                >
                  <option value="">Tipo de servicio</option>
                  <option value="Vivienda">Vivienda</option>
                  <option value="Comercio">Comercio</option>
                </select>
              </div>
              <div className="form-control">
                <select
                  {...register("city")}
                  className="text-area"
                  id="city"
                  required
                >
                  <option value="">Ciudad</option>
                  <option value="Bogotá">Bogotá</option>
                  <option value="Medellín">Medellín</option>
                  <option value="Barranquilla">Barranquilla</option>
                </select>
              </div>
              <div className="form-control">
                <input
                  type="text"
                  id="neighborhood"
                  placeholder="Barrio"
                  required
                />
              </div>

              <div className="lg:w-96">
                <HabeasData />
              </div>

              <div className="form-control is-checkbox">
                <input type="checkbox" id="data-policy" required />
                <label htmlFor="data-policy" className="data-policy">
                  Autorizo el tratamiento y la{" "}
                  <a
                    className="co-link is-primary"
                    href="https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa"
                    target="_blank"
                  >
                    Política de datos personales
                  </a>
                </label>
              </div>
              <Button disabled={isSubmitting}>
                {!isSubmitting ? "Enviar" : "Enviando ..."}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </Section>
  );
}
