import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Banner from "@coninsa-s2/banner";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import IconButton from "@coninsa-s2/icon-button";

import OurRecognitions from "../../../sections/our-recognitions";

import socialBanner from "@coninsa-site/sustainability/assets/images/social/social-banner.png";
import socialMobileBanner from "@coninsa-site/sustainability/assets/images/social/social-mobile-banner.png";
import contractorsImage from "@coninsa-site/sustainability/assets/images/social/contractors.png";
import alliesImage from "@coninsa-site/sustainability/assets/images/social/allies.png";
import diversityImage from "@coninsa-site/sustainability/assets/images/social/diversity.png";
import collaboratorImage from "@coninsa-site/sustainability/assets/images/social/collaborator.png";
import customerExperienceImage from "@coninsa-site/sustainability/assets/images/social/customer-experience.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const data = [
  {
    id: "social-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores",
    picture: contractorsImage,
    title: "Desarrollo de Contratistas y Proveedores",
  },
  {
    id: "social-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/gestion-de-aliados",
    picture: alliesImage,
    title: "Gestión de Aliados",
  },
  {
    id: "social-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/diversidad-e-inclusion",
    picture: diversityImage,
    title: "Diversidad e Inclusión",
  },
  {
    id: "social-04",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/bienestar-del-colaborador",
    picture: collaboratorImage,
    title: "Bienestar del Coladorador",
  },
  {
    id: "social-05",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/experiencia-del-cliente",
    picture: customerExperienceImage,
    title: "Experiencia del Cliente",
  },
];

export default function SustainabilitySocialPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 4,
    padding: { left: 0, right: "70px" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Banner imageUrl={socialBanner} imageUrlMobile={socialMobileBanner}>
        <Container>
          <IconButton
            href="/corporativo/sostenibilidad"
            className="relative -mt-4 mb-4 lg:mb-8"
          >
            <LeftArrowIcon />
            Regresar a sostenibilidad
          </IconButton>
          <h1 className="text-3xl font-extrabold text-white lg:w-[470px] lg:text-5xl">
            Sostenibilidad
            <span className="block text-center font-handwritting text-7xl font-medium text-s2-lime lg:text-end lg:text-9xl">
              Social
            </span>
          </h1>
        </Container>
      </Banner>
      <Section tint="ligther-gray">
        <Container>
          <div className="mb-16 text-s2-gray-600 lg:text-lg">
            <span className="font-bold">
              En Coninsa somos conscientes que nuestras operaciones generan
              impactos en el entorno
            </span>
            , por esta razón, estamos comprometidos en la construcción de
            bienestar a través de la consolidación de la sostenibilidad, por eso
            hemos creado nuestra{" "}
            <span className="font-bold">Dirección de Sostenibilidad</span>{" "}
            enfocada en la creación de valor para nuestras partes interesadas
            considerando el crecimiento económico, el bienestar de las personas
            y la protección del ambiente.
          </div>

          <Splide
            options={sliderOptions}
            className="s2-slider s2-slider--lg mt-5"
          >
            {data.map((item) => (
              <SplideSlide key={item.id}>
                <BubbleCard.Root image={item.picture} className="lg:h-720 h-64">
                  <BubbleCard.Content className="min-h-24 bg-s2-teal">
                    <h4 className="text-center text-lg font-bold">
                      {item.title}
                    </h4>
                  </BubbleCard.Content>
                  <BubbleCard.Anchor href={item.url} />
                </BubbleCard.Root>
              </SplideSlide>
            ))}
          </Splide>
        </Container>
      </Section>

      <OurRecognitions />
    </div>
  );
}
