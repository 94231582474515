import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "theme-ui";

import Button from "@coninsa-s2/button";
import BuildingContactForm from "./BuildingContactForm";

import { Icon } from "ui";
import {
  getNormalizerServiceType,
  getNormalizeRegionPhone,
} from "modules/search-building/utils";

function BuildingFloatingBar({
  canon,
  code,
  path,
  region,
  serviceType,
  propertyType,
  title,
}) {
  const [expanded, setExpanded] = useState(false);
  const [displayedForm, setDisplayedForm] = useState(false);

  const phoneAR = `3014726883`;
  const phoneVE = `3014726883`;
  const service = getNormalizerServiceType(serviceType);
  const regionPhone = getNormalizeRegionPhone(region);
  const phone = service === "AR" ? phoneAR : phoneVE;
  const whatsappPath = `https://api.whatsapp.com/send?phone=57${phone}&text=Hola! Los vi en coninsa.co, me gustaría obtener más información. Inmueble: ${code}`;

  const baseStyles = {
    bg: "white",
    display: "flex",
    gap: "medium",
    justifyContent: ["space-between", "space-evenly"],
    alignItems: ["flex-start", "center"],
    boxShadow: "0px -3px 6px #00000029",
    opacity: "1",
    px: ["medium", "large"],
    py: "18px",
    borderRadius: "10px 10px 0px 0px",
  };

  function handleDropshadowClick(event) {
    // Hide the dropshadow when the user clicks outside of it.
    if (event.target.classList.contains("s2-dropshadow")) {
      setExpanded(false);
    }
  }

  return (
    <div>
      {expanded && (
        <div className="s2-dropshadow inset-0 fixed h-full z-50 bg-[#00000080]" onClick={handleDropshadowClick}>
          <div className="bg-white absolute bottom-0 rounded-tr-xl rounded-tl-xl p-4 w-full">
            <h5 className="text-center font-bold text-sm mb-6">Más opciones</h5>

            <div className="flex flex-col gap-4">
              {/* <Button
                href="/estudio-digital"
                target="_blank"
                variant="ghost"
                size="fluid"
              >
                <Icon name="fa-reg-file" />
                Iniciar mi estudio digital
              </Button> */}

              <Button
                size="fluid"
                inverted
                onClick={() => setDisplayedForm(!displayedForm)}
              >
                <Icon name="fa-calendar" />
                Agendar una cita
              </Button>

              {displayedForm && (
                <BuildingContactForm
                  code={code}
                  canon={canon}
                  path={path}
                  propertyType={propertyType}
                  region={region}
                  serviceType={serviceType}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="block md:hidden fixed bottom-0 left-0 right-0 z-40">
        <Box sx={baseStyles}>
          <Button
            className="!text-base"
            size="fluid"
            tint="green"
            href={whatsappPath}
            target="_blank"
          >
            <Icon name="fa-whatsapp" />
            Contactar a un asesor
          </Button>

          <button
            className="text-3xl font-bold text-coninsa-blue-900"
            aria-label="Más opciones"
            onClick={() => setExpanded(true)}
          >
            ...
          </button>
        </Box>
      </div>

      <div className="hidden md:block fixed bottom-0 left-0 right-0 z-20">
        <Box sx={baseStyles}>

          <h3 className="text-coninsa-blue-900 font-display text-[22px] font-medium">{title}</h3>
          <div className="flex items-center gap-1">
            <Icon name="bi-wallet" sx={{ fontSize: 5 }} />
            <div className="flex items-end">

              <h4 className="font-display font-medium text-2xl">
                ${parseInt(canon).toLocaleString("es-CO")}
              </h4>
              <span
                className="text-gray-400 font-display font-medium"
              >
                /mes
              </span>
            </div>
          </div>

          <Button
            href={whatsappPath}
            target="_blank"
            tint="green"
          >
            <Icon name="fa-whatsapp" />
            Contactar a un asesor
          </Button>
        </Box>
      </div>
    </div>
  );
}

BuildingFloatingBar.propTypes = {
  canon: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BuildingFloatingBar;
