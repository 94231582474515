import React from "react";
import Layout from "layout/default/Layout";

import SustainabilityReportsPage from "@coninsa-site/sustainability/src/pages/sustainability-reports";

function SustainabilityReports() {
  return (
    <Layout>
      <SustainabilityReportsPage />
    </Layout>
  );
}

export default SustainabilityReports;
