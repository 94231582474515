import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import DiversityInclusionBanner from "@coninsa-site/sustainability/assets/images/social/diversity-inclusion-banner.png";
import DiversityInclusionMobileBanner from "@coninsa-site/sustainability/assets/images/social/diversity-inclusion-mobile-banner.png";
import EdiImage from "@coninsa-site/sustainability/assets/images/social/blue-edi.svg";

import equityIcon from "@coninsa-site/sustainability/assets/images/social/equity.svg";
import ediIcon from "@coninsa-site/sustainability/assets/images/social/edi-icon.svg";
import inclusionIcon from "@coninsa-site/sustainability/assets/images/social/inclusion.svg";
import handsIcon from "@coninsa-site/sustainability/assets/images/social/hands.svg";
import diversityIcon from "@coninsa-site/sustainability/assets/images/social/diversity.svg";
import peopleIcon from "@coninsa-site/sustainability/assets/images/social/people.svg";
import puzzleIcon from "@coninsa-site/sustainability/assets/images/social/puzzle.svg";
import coninparIcon from "@coninsa-site/sustainability/assets/images/social/coninpar.svg";

import contractorsImage from "@coninsa-site/sustainability/assets/images/social/contractors.png";
import collaboratorImage from "@coninsa-site/sustainability/assets/images/social/collaborator.png";
import alliesImage from "@coninsa-site/sustainability/assets/images/social/allies.png";
import customerExperienceImage from "@coninsa-site/sustainability/assets/images/social/customer-experience.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const coninparInfo = [
  {
    id: "coninpar-1",
    tag: "Recibir y dar",
    title: "apoyo emocional",
    description:
      "Compartir con otras mujeres lo que piensan, sienten y escuchar situaciones similares a las que vivimos. Esto nos ayuda a desarrollar nuestra parte emocional, compartimos nuestras experiencias nos situamos en el lugar de la otra y eso nos hace ser más sensibles, empatizar y apoyar pero también recibir apoyo, permitiendo que se fortalézcanles las relaciones.",
  },
  {
    id: "coninpar-2",
    tag: "Conocer",
    title: "Personas inspiradoras",
    description:
      "Una manera de crecer de nuestro liderazgo y avanzar en nuestra visión de futuro es tener ejemplos tangibles de personas que han logrado sobresalir a pesar de las adversidades y de las situaciones retadoras, al conocer historias de otras mujeres resilientes, saber a qué se han tenido que enfrentar, como lograron sortear situaciones familiares, de salud y profesionales nos motivará a seguir soñando, seguir creciendo y haciendo las cosas posible.",
  },
  {
    id: "coninpar-3",
    tag: "Aprendizaje y",
    title: "nuevas herramientas",
    description:
      "Queremos conocer nuevas herramientas para el desarrollo de nuestros talentos, información sobre cómo gestionar mejor nuestras emociones, problemáticas sociales que puedan desestructurar el núcleo de la sociedad y cómo restablecerlo, temas sobre cómo ser un bien ser humano con cualquier organismo de vida y que nos permita ser y estar de manera integral con lo que nos rodea.",
  },
];

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores",
    image: contractorsImage,
    title: "Desarrollo de Contratistas y Proveedores",
  },
  {
    id: "ps-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/gestion-de-aliados",
    image: alliesImage,
    title: "Gestión de Aliados",
  },
  {
    id: "ps-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/bienestar-del-colaborador",
    image: collaboratorImage,
    title: "Bienestar del Coladorador",
  },
  {
    id: "ps-04",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/experiencia-del-cliente",
    image: customerExperienceImage,
    title: "Experiencia del Cliente",
  },
];

export default function DiversityInclusionPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    padding: { left: 0, right: "70px" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  const coninparOptions = {
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "50px" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="dark-blue" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-social"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <h3 className="text-2xl font-bold text-white">
              Sostenibilidad Social
            </h3>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={DiversityInclusionMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={DiversityInclusionBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-2xl font-extrabold text-white lg:w-[430px] lg:text-4xl">
                Equidad, diversidad e
                <span className="block text-end font-handwritting text-6xl font-medium text-s2-lime lg:text-8xl">
                  Inclusión
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container>
          <div className="grid-cols-12 gap-8 lg:grid lg:gap-4">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  Reconocemos la importancia de fomentar un entorno laboral
                  inclusivo y equitativo que celebre la diversidad de
                  experiencias, perspectivas y talentos, promoviendo activamente
                  la igualdad de oportunidades, la participación significativa
                  de todas las identidades y la creación de una cultura
                  organizacional en la que cada individuo se sienta valorado y
                  respaldado, contribuyendo así al crecimiento sostenible de
                  Coninsa.
                </p>
                <p className="font-bold">
                  Nuestro foco de acción en la primera fase 2024 – 2025 será la
                  equidad.
                </p>
              </div>
            </div>
            <div className="col-span-5 self-start rounded-xl shadow-[0px_3px_6px_#00000029]">
              <img src={EdiImage} alt="Edi icon" />
            </div>
          </div>
        </Container>
      </Section>

      <Section className="mb-28 lg:mb-32">
        <Container>
          <div className="mb-10">
            <h3 className="mb-5 text-center font-display text-4xl font-extralight uppercase text-s2-teal lg:mb-16 lg:text-5xl">
              Marco{" "}
              <span className="font-medium text-s2-dark-blue">de acción</span>
            </h3>
            <div className="flex grid-cols-3 flex-col place-items-center gap-8 lg:grid">
              <div className="lg:hidden">
                <img src={ediIcon} alt="Edi icon" />
              </div>
              <div>
                <img src={equityIcon} alt="Equity icon" />
                <div className="my-4 text-s2-gray-600">
                  <span className="font-bold">Creamos escenarios</span>{" "}
                  colectivos, donde las oportunidades se habilitan a todos y el
                  acceso a ellas se define desde las capacidades e intereses de
                  cada uno y que se conectan con las necesidades de la compañía.
                </div>
                <img src={handsIcon} alt="Hand icon" />
              </div>
              <div className="relative -mt-20 hidden lg:block">
                <img src={ediIcon} alt="Edi icon" />
              </div>
              <div>
                <img src={inclusionIcon} alt="Inclusion icon" />
                <div className="my-4 text-s2-gray-600">
                  <span className="font-bold">
                    Damos la Bienvenida a todo y todos
                  </span>{" "}
                  de manera genuina y desde nuestra propia identidad como
                  compañía, creando contextos que permitan el desarrollo natural
                  de las cosas, que no inviten a la explicación sino al actuar
                  natural a través de una participación activa, promoviendo la
                  colaboración en la toma de decisiones y la co-creación en los
                  distintos espacios, independientemente de su origen o
                  identidad.
                </div>
                <img src={peopleIcon} alt="People icon" />
              </div>
              <div className="col-span-3 grid-cols-subgrid gap-4 lg:grid">
                <div className="col-start-2">
                  <img src={diversityIcon} alt="Diversity icon" />
                  <div className="my-4 text-s2-gray-600">
                    <span className="font-bold">Defendemos el respeto</span> y
                    nos permitimos ser diversos, no desde la aceptación sino
                    desde la estimación que le damos a ser diferentes, a
                    entender cómo desde la posición de cada uno contribuimos a
                    la construcción de valor y que se inspira en nuestro
                    propósito superior Construimos Bienestar.
                  </div>
                  <img src={puzzleIcon} alt="Puzzle icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto text-balance rounded-xl bg-s2-gray-100 px-5 py-4 text-center text-s2-gray-600 lg:w-[880px] lg:px-11 lg:py-9 lg:text-lg">
            Tenemos la convicción de que en Coninsa existe una disposición
            genuina y constante en crear y fortalecer vínculos de calidad,
            sentimientos de confianza y conversaciones constructivas que nos
            orienten hacia la consolidación de nuestro propósito superior de
            Construimos Bienestar.
          </div>
        </Container>
      </Section>

      <Section tint="ligther-gray">
        <Container className="mb-6">
          <div className="relative -mt-36 mb-16 bg-s2-dark-blue px-5 py-4 text-center text-white lg:px-28 lg:py-12 lg:text-xl">
            <span className="font-bold">
              Nace entonces la necesidad de conformar una comunidad en la cual
              encontraremos:
            </span>{" "}
            inspiración, motivación, aprendizajes. Juntos, logremos un espacio
            de apoyo, para afrontar y dialogar sobre situaciones retadoras que
            vivimos día a día.
          </div>
          <div>
            <h3 className="mb-10">
              <img
                src={coninparIcon}
                alt="Comunidad Coninpar"
                className="mx-auto"
              />
            </h3>
            <Splide
              options={coninparOptions}
              className="s2-slider s2-slider--lg lg:hidden"
            >
              {coninparInfo.map((coninpar) => (
                <SplideSlide className="py-4" key={coninpar.id}>
                  <div className="h-[450px] rounded-xl bg-white px-5 py-4 shadow-[0px_3px_6px_#00000029]">
                    <h4 className="relative -mt-8 mb-6 rounded-xl bg-s2-purple px-6 py-4 text-xl text-white">
                      {coninpar.tag}
                      <span className="block font-bold leading-4">
                        {coninpar.title}
                      </span>
                    </h4>
                    <div className="text-s2-gray-600">
                      {coninpar.description}
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </Splide>

            <div className="hidden grid-cols-3 gap-4 lg:grid">
              <div className="rounded-xl bg-white px-5 py-4 shadow-[0px_3px_6px_#00000029]">
                <h4 className="relative -mt-10 mb-8 rounded-xl bg-s2-purple px-6 py-4 text-2xl text-white">
                  Recibir y dar
                  <span className="block font-bold leading-4">
                    apoyo emocional
                  </span>
                </h4>
                <div className="text-s2-gray-600">
                  Compartir con otras mujeres lo que piensan, sienten y escuchar
                  situaciones similares a las que vivimos. Esto nos ayuda a
                  desarrollar nuestra parte emocional, compartimos nuestras
                  experiencias nos situamos en el lugar de la otra y eso nos
                  hace ser más sensibles, empatizar y apoyar pero también
                  recibir apoyo, permitiendo que se fortalézcanles las
                  relaciones.
                </div>
              </div>
              <div className="rounded-xl bg-white px-5 py-6 shadow-[0px_3px_6px_#00000029] lg:mt-8">
                <h4 className="relative -mt-10 mb-8 rounded-xl bg-s2-purple px-6 py-4 text-2xl text-white">
                  Conocer
                  <span className="block font-bold leading-4">
                    Personas inspiradoras
                  </span>
                </h4>
                <div className="text-s2-gray-600">
                  Una manera de crecer de nuestro liderazgo y avanzar en nuestra
                  visión de futuro es tener ejemplos tangibles de personas que
                  han logrado sobresalir a pesar de las adversidades y de las
                  situaciones retadoras, al conocer historias de otras mujeres
                  resilientes, saber a qué se han tenido que enfrentar, como
                  lograron sortear situaciones familiares, de salud y
                  profesionales nos motivará a seguir soñando, seguir creciendo
                  y haciendo las cosas posible.
                </div>
              </div>
              <div className="rounded-xl bg-white px-5 py-6 shadow-[0px_3px_6px_#00000029]">
                <h4 className="relative -mt-10 mb-8 rounded-xl bg-s2-purple px-6 py-4 text-2xl text-white">
                  Aprendizaje y
                  <span className="block font-bold leading-4">
                    nuevas herramientas
                  </span>
                </h4>
                <div className="text-s2-gray-600">
                  Queremos conocer nuevas herramientas para el desarrollo de
                  nuestros talentos, información sobre cómo gestionar mejor
                  nuestras emociones, problemáticas sociales que puedan
                  desestructurar el núcleo de la sociedad y cómo restablecerlo,
                  temas sobre cómo ser un bien ser humano con cualquier
                  organismo de vida y que nos permita ser y estar de manera
                  integral con lo que nos rodea.
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Social
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h3 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h3>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-teal">
                      <h4 className="text-center text-lg font-bold text-white lg:text-xl">
                        {item.title}
                      </h4>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
