import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import economicPerformanceBanner from "@coninsa-site/sustainability/assets/images/economic/economic-performance-banner.png";
import economicPerformanceMobileBanner from "@coninsa-site/sustainability/assets/images/economic/economic-performance-mobile-banner.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

import digitalTransformImage from "@coninsa-site/sustainability/assets/images/economic/digital-transform.png";

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-economica/transformacion-digital",
    image: digitalTransformImage,
    title: "Transformación digital, procesos e innovación",
  },
];

export default function EconomicPerformancePage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 1,
    padding: { left: "6rem", right: 0 },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        perPage: 1,
        padding: { left: "2rem", right: "2rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="mint" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-economica"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <h3 className="text-2xl font-bold text-white">
              Sostenibilidad Económica
            </h3>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={economicPerformanceMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={economicPerformanceBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-2xl font-extrabold text-white lg:w-[430px] lg:text-center lg:text-4xl">
                Desempeño
                <span className="block text-center font-handwritting text-6xl font-medium text-s2-dark-blue lg:text-end lg:text-8xl">
                  Económico
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container className="mb-6">
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  Nuestro compromiso es claro: fortalecer el rendimiento
                  económico de nuestra compañía de manera sólida y sostenible.
                  Nos comprometemos plenamente a que cada avance que logremos
                  esté en línea con nuestros principios fundamentales y nuestras
                  responsabilidades hacia la sociedad y el medio ambiente.
                </p>
                <p>
                  Adoptamos una perspectiva integral, conscientes de que mejorar
                  la eficiencia financiera y la rentabilidad no son los únicos
                  objetivos que debemos perseguir. También nos esforzamos por
                  ampliar nuestro impacto positivo en la sociedad y en el
                  entorno que nos rodea. Esta visión holística es fundamental
                  para asegurar un futuro próspero y equilibrado.
                </p>
              </div>
            </div>
            <div className="col-span-5 self-start">
              <h4 className="mx-8 rounded-t-2xl bg-s2-mint px-8 py-5 text-center text-xl font-bold text-white lg:mx-16 lg:px-8 lg:py-8 lg:text-2xl">
                Nuestro enfoque
              </h4>
              <div className="prose mb-4 rounded-lg bg-white p-7 text-s2-gray-600 shadow-[0px_3px_6px_#00000029]">
                <p className="lg:text-lg">
                  Mirando hacia adelante, mantenemos nuestro compromiso con este
                  enfoque integrador. Estamos convencidos de que esta es la ruta
                  más adecuada hacia un crecimiento sostenible, que nos
                  permitirá generar rentabilidad a largo plazo sin comprometer
                  los valores que nos definen como empresa.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Económica
          </SpeechBubble>
          <div className="items-center justify-start gap-4 lg:flex">
            <h3 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h3>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-green">
                      <h4 className="text-center text-lg font-bold leading-5 text-white lg:text-[22px]">
                        {item.title}
                      </h4>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
