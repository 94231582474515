import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import CustomerExperienceBanner from "@coninsa-site/sustainability/assets/images/social/customer-experience-banner.png";
import CustomerExperienceMobileBanner from "@coninsa-site/sustainability/assets/images/social/customer-experience-mobile-banner.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";
import thumbFingerIcon from "@coninsa-site/sustainability/assets/images/social/thumb-finger.svg";

import contractorsImage from "@coninsa-site/sustainability/assets/images/social/contractors.png";
import collaboratorImage from "@coninsa-site/sustainability/assets/images/social/collaborator.png";
import alliesImage from "@coninsa-site/sustainability/assets/images/social/allies.png";
import diversityImage from "@coninsa-site/sustainability/assets/images/social/diversity.png";

const strategies = [
  {
    id: "str-1",
    number: "1",
    title: "Marca sólida y con excelente reputación:",
    description:
      "Posicionamiento, gestión de redes sociales, imagen corporativa, manejo de reputación y relacionamiento con gremios.",
  },
  {
    id: "str-2",
    number: "2",
    title: "Programa ADN comercial y de experiencia del cliente:",
    description: "Movilización de la cultura centrada en el cliente.",
  },
  {
    id: "str-3",
    number: "3",
    title: "Diseño y gestión de la experiencia:",
    description:
      "Viaje del cliente y puntos de contacto, arquetipos, diseño de la experiencia, medición y valoración.",
  },
  {
    id: "str-4",
    number: "4",
    title: "Excelencia comercial y de servicio:",
    description:
      "Formación, entrenamiento y apropiación de equipos comerciales y administrativos, auditorías de servicio y protocolos.",
  },
];

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores",
    image: contractorsImage,
    title: "Desarrollo de Contratistas y Proveedores",
  },
  {
    id: "ps-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/gestion-de-aliados",
    image: alliesImage,
    title: "Gestión de Aliados",
  },
  {
    id: "ps-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/diversidad-e-inclusion",
    image: diversityImage,
    title: "Diversidad e Inclusión",
  },
  {
    id: "ps-04",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/bienestar-del-colaborador",
    image: collaboratorImage,
    title: "Bienestar del Coladorador",
  },
];

export default function CustomerExperiencePage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    padding: { left: 0, right: "70px" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="dark-blue" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-social"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <h3 className="text-2xl font-bold text-white">
              Sostenibilidad Social
            </h3>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={CustomerExperienceMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={CustomerExperienceBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-2xl font-extrabold text-white lg:w-[430px] lg:text-4xl">
                Experiencia del
                <span className="block text-end font-handwritting text-6xl font-medium text-s2-lime lg:text-8xl">
                  Cliente
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container className="pb-16">
          <div className="grid-cols-12 place-content-center gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  En un mercado tan competitivo y dinámico, mejorar la
                  experiencia del cliente es fundamental. Nos enfrentamos a este
                  desafío con determinación, aprovechando al máximo nuestras
                  fortalezas para contribuir a los objetivos estratégicos de la
                  compañía. La experiencia del cliente se convierte en un
                  diferenciador clave de valor en esta estrategia.
                </p>
              </div>
            </div>
            <div className="col-span-5">
              <img
                src={thumbFingerIcon}
                alt="Thumb finger icon"
                className="mx-auto h-40 w-40"
              />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container className="mb-6 pb-4 lg:pb-16">
          <div className="relative mx-auto -mt-28 mb-8 rounded-xl bg-s2-dark-blue px-16 py-8 text-white lg:w-[772px] lg:text-xl">
            Gestionamos una variedad de aspectos centrados en cuatro pilares
            fundamentales,{" "}
            <span className="font-bold">
              respaldados por una sólida estrategia digital
            </span>
          </div>
          <div className="ml-6 flex grid-cols-2 flex-col gap-10 lg:ml-0 lg:grid lg:gap-12">
            {strategies.map((item) => (
              <div
                className="flex items-center rounded-xl border-l-[6px] border-s2-mint bg-white px-3 pb-4 pt-9 text-start shadow-[0px_3px_6px_#00000029] lg:gap-4 lg:px-4 lg:py-6"
                key={item.id}
              >
                <h3 className="relative -ml-8 -mt-[168px] rounded-full bg-s2-teal px-5 py-[10px] text-3xl font-extrabold text-white lg:-ml-12 lg:mt-0">
                  {item.number}
                </h3>
                <div>
                  <h4 className="mb-3 text-lg font-bold leading-5 text-s2-dark-blue lg:text-xl">
                    {item.title}
                  </h4>
                  <div className="text-s2-gray-600 lg:text-lg">
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Social
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h3 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h3>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-teal">
                      <h4 className="text-center text-lg font-bold text-white lg:text-xl">
                        {item.title}
                      </h4>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
