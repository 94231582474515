import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Banner from "@coninsa-s2/banner";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import IconButton from "@coninsa-s2/icon-button";

import OurRecognitions from "../../../sections/our-recognitions";

import environmentalBanner from "@coninsa-site/sustainability/assets/images/environmental/environmental-banner.png";
import environmentalMobileBanner from "@coninsa-site/sustainability/assets/images/environmental/environmental-mobile-banner.png";
import coninsaGreenIcon from "@coninsa-site/sustainability/assets/images/environmental/coninsa-green.svg";
import economyImage from "@coninsa-site/sustainability/assets/images/environmental/economy.png";
import climateChangeImage from "@coninsa-site/sustainability/assets/images/environmental/climate-change.png";
import biodiversityImage from "@coninsa-site/sustainability/assets/images/environmental/biodiversity.png";
import constructionImage from "@coninsa-site/sustainability/assets/images/environmental/construction.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

const data = [
  {
    id: "env-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/economia-circular",
    image: economyImage,
    title: "Economía Circular",
  },
  {
    id: "env-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/cambio-climatico",
    image: climateChangeImage,
    title: "Cambio climático",
  },
  {
    id: "env-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/biodiversidad",
    image: biodiversityImage,
    title: "Biodiversidad",
  },
  {
    id: "env-04",
    url: "/corporativo/sostenibilidad/sostenibilidad-ambiental/construccion-sostenible",
    image: constructionImage,
    title: "Construcción sostenible",
  },
];

export default function SustainabilityEnvironmentalPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 4,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Banner
        imageUrl={environmentalBanner}
        imageUrlMobile={environmentalMobileBanner}
      >
        <Container>
          <IconButton
            href="/corporativo/sostenibilidad"
            className="relative -mt-4 mb-4 lg:mb-8"
          >
            <LeftArrowIcon />
            Regresar a sostenibilidad
          </IconButton>
          <h1 className="text-3xl font-extrabold text-white lg:w-[470px] lg:text-5xl">
            Sostenibilidad
            <span className="block text-center font-handwritting text-7xl font-medium text-s2-dark-blue lg:text-end lg:text-9xl">
              Ambiental
            </span>
          </h1>
        </Container>
      </Banner>

      <Section>
        <Container>
          <div className="grid-tem grid-cols-3 justify-between gap-4 lg:grid">
            <div className="col-span-2 mb-6">
              <div className="fs mb-4 text-balance text-s2-gray-600 lg:mb-6 lg:text-lg">
                En <span className="font-bold">Coninsa + Verde </span>
                reflejamos nuestro compromiso con la gestión ambiental y el
                trabajo que estamos realizando por un desarrollo sostenible. Nos
                dedicamos con determinación a promover la economía circular,
                combatir el cambio climático, preservar la biodiversidad y
                fomentar la construcción sostenible.
              </div>
              <div className="font-bold text-s2-teal lg:text-lg">
                Estas acciones definen nuestra ruta hacia una gestión ambiental
                responsable.
              </div>
            </div>
            <div className="col-span-1 object-cover lg:h-40 lg:w-[360px]">
              <img
                src={coninsaGreenIcon}
                alt="Coninsa green icon"
                className="mx-auto h-32 w-auto object-cover lg:h-40 lg:w-[360px]"
              />
            </div>
          </div>
        </Container>
      </Section>

      <Section tint="ligther-gray">
        <Container>
          <div className="mb-16 text-center text-s2-gray-600 lg:text-lg">
            <span className="font-bold">
              En Coninsa somos conscientes que nuestras operaciones generan
              impactos en el entorno{" "}
            </span>
            , por esta razón, estamos comprometidos en la construcción de
            bienestar a través de la consolidación de la sostenibilidad, por eso
            hemos creado nuestra
            <span className="font-bold"> Dirección de Sostenibilidad</span>{" "}
            enfocada en la creación de valor para nuestras partes interesadas
            considerando el crecimiento económico, el bienestar de las personas
            y la protección del ambiente.
          </div>

          <Splide
            options={sliderOptions}
            className="s2-slider s2-slider--lg mt-5"
          >
            {data.map((item) => (
              <SplideSlide key={item.id}>
                <BubbleCard.Root image={item.image} className="h-72 lg:h-80">
                  <BubbleCard.Content className="min-h-24">
                    <h4 className="text-lg font-bold lg:text-[22px]">
                      {item.title}
                    </h4>
                  </BubbleCard.Content>
                  <BubbleCard.Anchor href={item.url} />
                </BubbleCard.Root>
              </SplideSlide>
            ))}
          </Splide>
        </Container>
      </Section>

      <OurRecognitions />
    </div>
  );
}
