import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import IconButton from "@coninsa-s2/icon-button";

import corporateGovernanceBanner from "@coninsa-site/sustainability/assets/images/corporate-governance/corporate-governance-banner.png";
import corporateGovernanceMobileBanner from "@coninsa-site/sustainability/assets/images/corporate-governance/corporate-governance-mobile-banner.png";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";

import anticorruptionPracticesImage from "@coninsa-site/sustainability/assets/images/corporate-governance/anticorruption-practices.png";
import humanRightsImage from "@coninsa-site/sustainability/assets/images/corporate-governance/human-rights.png";

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/gobierno-corporativo/practicas-anticorrupcion",
    image: anticorruptionPracticesImage,
    title: "Prácticas anticorrupción",
  },
  {
    id: "ps-02",
    url: "/corporativo/sostenibilidad/gobierno-corporativo/derechos-humanos",
    image: humanRightsImage,
    title: "Derechos humanos",
  },
];

export default function CorporateGovernancePage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 2,
    padding: { left: "10rem", right: "10rem" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "7rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="teal" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/gobierno-corporativo"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <h3 className="text-2xl font-bold text-white">
              Gobierno corporativo
            </h3>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={corporateGovernanceMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={corporateGovernanceBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-2xl font-extrabold text-white lg:w-[430px] lg:text-center lg:text-4xl">
                Gobierno
                <span className="block text-center font-handwritting text-6xl font-medium text-s2-dark-blue lg:text-end lg:text-8xl">
                  Corporativo
                </span>
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container className="mb-6">
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  Para asegurar la transparencia en nuestras acciones y
                  decisiones, en Coninsa hemos establecido una sólida estructura
                  de gobernanza. Esta tiene como objetivo orientar las acciones
                  de los diferentes roles, cargos y equipos hacia una toma de
                  decisiones oportuna, eficiente y acertada, manteniendo siempre
                  la agilidad y la consideración de las partes interesadas en
                  mente.
                </p>
                <p>
                  Nuestra estructura de gobernanza está compuesta por la
                  Asamblea, la Junta Directiva, la Presidencia y las Gerencias
                  de la organización. Es importante destacar que el presidente
                  del máximo órgano de gobierno de la compañía no forma parte de
                  la estructura administrativa.
                </p>
              </div>
            </div>
            <div className="col-span-5 self-start">
              <h4 className="mx-12 rounded-t-2xl bg-s2-mint px-20 py-5 text-center text-xl font-bold text-white lg:mx-24 lg:px-28 lg:py-8 lg:text-2xl">
                Comités
              </h4>
              <div className="prose mb-4 rounded-lg bg-white p-7 text-s2-gray-600 shadow-[0px_3px_6px_#00000029]">
                <p className="lg:text-lg">
                  Dentro de esta estructura, contamos con comités dedicados a la
                  toma de decisiones en asuntos económicos, ambientales y
                  sociales, los cuales desempeñan un papel fundamental en la
                  dirección estratégica de la empresa, estos comités son:
                </p>
                <div className="gap-4 lg:flex">
                  <ul className="leading-5 marker:!text-s2-lime">
                    <li>Junta Directiva</li>
                    <li>Junta de inversiones</li>
                    <li>Comité de presidencia</li>
                    <li>Comités de USC y UEN</li>
                    <li>Comités de construcción</li>
                  </ul>
                  <ul className="leading-5 marker:!text-s2-lime">
                    <li>Comités de obra</li>
                    <li>Comités de Proyectos</li>
                    <li>Comités de capacidades</li>
                    <li>Comité de riesgos</li>
                  </ul>
                </div>
              </div>
              <div className="text-sm text-s2-gray-600">
                <p>
                  <span className="font-bold">*UEN:</span> Unidad estratégica de
                  negocio.
                </p>
                <p>
                  <span className="font-bold">*USC:</span> Unidad de Servicios
                  Corporativos.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Gobierno
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h3 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas{" "}
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h3>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-lime">
                      <h4 className="text-center text-lg font-bold leading-5 text-white lg:text-[22px]">
                        {item.title}
                      </h4>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
