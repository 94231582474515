import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as BubbleCard from "@coninsa-s2/bubble-card";
import SpeechBubble from "@coninsa-s2/speech-bubble";
import * as Accordion from "@coninsa-s2/accordion";
import Button from "@coninsa-s2/button";
import IconButton from "@coninsa-s2/icon-button";

import contractorSupplierBanner from "@coninsa-site/sustainability/assets/images/social/contractor-supplier-banner.png";
import contractorSupplierMobileBanner from "@coninsa-site/sustainability/assets/images/social/contractor-supplier-mobile-banner.png";
import pdfIcon from "@coninsa-site/sustainability/assets/images/social/pdf.svg";
import sheetIcon from "@coninsa-site/sustainability/assets/images/social/sheet.svg";
import checkIcon from "@coninsa-site/sustainability/assets/images/social/check.svg";
import LeftArrowIcon from "@coninsa-site/sustainability/assets/icons/left-arrow.inline.svg";
import PlusCircleIcon from "@coninsa-site/sustainability/assets/icons/plus-circle.inline.svg";
import MinusCircleIcon from "@coninsa-site/sustainability/assets/icons/minus-circle.inline.svg";

import alliesImage from "@coninsa-site/sustainability/assets/images/social/allies.png";
import diversityImage from "@coninsa-site/sustainability/assets/images/social/diversity.png";
import collaboratorImage from "@coninsa-site/sustainability/assets/images/social/collaborator.png";
import customerExperienceImage from "@coninsa-site/sustainability/assets/images/social/customer-experience.png";

const pillars = [
  {
    id: "pillar-1",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores/requisitos-empresas-contratistas",
    icon: sheetIcon,
    tag: "Requisitos generales para",
    title: "Las Empresas Contratistas",
  },
  {
    id: "pillar-2",
    // url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores/requisitos-seleccion-contratistas",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/desarrollo-de-contratistas-y-proveedores/#",
    icon: checkIcon,
    tag: "Requerimientos de",
    title: "Selección para Contratistas",
  },
];

const data = [
  {
    id: "ps-01",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/gestion-de-aliados",
    image: alliesImage,
    title: "Gestión de Aliados",
  },
  {
    id: "ps-02",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/diversidad-e-inclusion",
    image: diversityImage,
    title: "Diversidad e Inclusión",
  },
  {
    id: "ps-03",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/bienestar-del-colaborador",
    image: collaboratorImage,
    title: "Bienestar del Coladorador",
  },
  {
    id: "ps-04",
    url: "/corporativo/sostenibilidad/sostenibilidad-social/experiencia-del-cliente",
    image: customerExperienceImage,
    title: "Experiencia del Cliente",
  },
];

export default function ContractorSuppliersPage() {
  const sliderOptions = {
    arrows: false,
    gap: 12,
    perPage: 3,
    padding: { left: 0, right: "70px" },
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 12,
        perPage: 1,
        padding: { left: 0, right: "6rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="dark-blue" className="mb-5 !py-5 lg:mb-8">
        <Container>
          <div className="items-center gap-4 lg:flex">
            <IconButton
              href="/corporativo/sostenibilidad/sostenibilidad-social"
              className="mb-5 lg:mb-0"
            >
              <LeftArrowIcon />
              Regresar
            </IconButton>
            <h3 className="text-2xl font-bold text-white">
              Sostenibilidad Social
            </h3>
          </div>
        </Container>
      </Section>
      <Container className="mb-10">
        <div className="">
          <div className="flex justify-center">
            <img
              src={contractorSupplierMobileBanner}
              alt=""
              className="absolute block lg:hidden"
            />
          </div>

          <img
            src={contractorSupplierBanner}
            alt=""
            className="absolute hidden lg:block"
          />

          <div className="relative z-10 pt-56 lg:pt-10">
            <Container>
              <h1 className="text-xl font-extrabold text-white lg:text-4xl">
                <span className="block font-handwritting text-7xl font-medium text-s2-lime lg:text-8xl">
                  Desarrollo
                </span>
                de contratistas y proveedores
              </h1>
            </Container>
          </div>
        </div>
      </Container>

      <Section tint="ligther-gray">
        <Container>
          <div className="grid-cols-12 justify-between gap-8 lg:grid">
            <div className="col-span-7 mb-6">
              <div className="prose mt-8 text-balance rounded-[4px] border-l-4 border-s2-lime pl-4 text-s2-gray-600 lg:mt-0 lg:border-l-8 lg:pl-5 lg:text-lg">
                <p>
                  En Coninsa, nos comprometemos con{" "}
                  <span className="font-bold">prácticas sostenibles</span> que
                  promueven el bienestar de las personas y la protección del
                  medio ambiente.
                </p>
                <p>
                  A través de nuestro liderazgo, valores corporativos y pilares
                  de comportamiento, buscamos generar transparencia y
                  credibilidad con nuestras partes interesadas.
                </p>
                <p>
                  Bajo nuestro propósito de{" "}
                  <span className="font-bold">“Construimos Bienestar”</span>,
                  trabajamos en proyectos que integran aspectos sociales,
                  económicos y ambientales, enfocándonos en maximizar los
                  impactos positivos y minimizar los negativos. Además, nos
                  esforzamos por incorporar estratégicamente los objetivos de
                  desarrollo sostenible prioritarios para el sector de la
                  construcción.
                </p>
              </div>
            </div>
            <div className="col-span-5">
              <h4 className="font-display text-2xl font-medium uppercase text-s2-teal lg:text-3xl">
                Nuestras políticas
              </h4>

              <Accordion.Root
                type="single"
                // defaultValue="item-1"
                collapsible
              >
                <Accordion.Item value="item-1">
                  <Accordion.Trigger
                    expandedIcon={PlusCircleIcon}
                    collapsedIcon={MinusCircleIcon}
                  >
                    <h5 className="text-left text-lg font-medium text-s2-dark-blue">
                      Política de Gestión Integral
                    </h5>
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <div className="prose text-sm text-s2-gray-600">
                      <p>
                        En CONINSA trabajamos con el compromiso de satisfacer a
                        nuestras partes interesadas, creciendo mutuamente con
                        nuestros colaboradores, creando valor agregado para los
                        accionistas y transformando el hábitat en pro de una
                        mejor calidad de vida con enfoque de sostenibilidad.
                      </p>
                      <p>
                        Entregamos obras de infraestructura y edificaciones
                        públicas y privadas, que satisfacen las necesidades y
                        expectativas de nuestros clientes, cumpliendo con los
                        requisitos legales, plazos, presupuestos y
                        especificaciones técnicas.
                      </p>
                      <p>
                        Generamos espacios de trabajo seguros, a partir de la
                        gestión de riesgos, para prevenir los accidentes de
                        trabajo, las enfermedades laborales, las afectaciones al
                        ambiente y los daños a la propiedad. Contamos con
                        talento humano competente para diseñar y construir
                        proyectos con altos estándares de calidad, a través de
                        la integración estratégica de proveedores altamente
                        comprometidos con los procesos establecidos bajo la
                        metodología del aprendizaje, destinando los recursos
                        necesarios para consolidar la cultura del mejoramiento
                        continuo y el sistema de gestión integrado.
                      </p>
                    </div>
                  </Accordion.Content>
                </Accordion.Item>

                <Accordion.Item value="item-2">
                  <Accordion.Trigger
                    expandedIcon={PlusCircleIcon}
                    collapsedIcon={MinusCircleIcon}
                  >
                    <h5 className="text-left text-lg font-medium text-s2-dark-blue">
                      Política de prevención de alcoholismo y drogadicción
                    </h5>
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <div className="prose text-sm text-s2-gray-600">
                      <p>
                        En nuestra Construcción de Bienestar, hemos declarado la
                        prohibición de los hábitos y acciones que no son
                        saludables. Es por ello que dentro de las instalaciones
                        de Coninsa, se prohíbe el porte, comercialización y
                        consumo de sustancias psicoactivas prohibidas, así como
                        el ingreso bajo la influencia de este tipo de sustancias
                        y alcohol. A fin de controlar el consumo de sustancias
                        nocivas, motivará a los colaboradores mediante programas
                        de formación a la correcta utilización de su tiempo
                        libre, al mejoramiento y cuidado de su salud.
                      </p>
                      <p class="font-bold">
                        Solo se permitirá fumar cigarrillos en los sitios
                        establecidos para tal fin.
                      </p>
                    </div>
                  </Accordion.Content>
                </Accordion.Item>

                <Accordion.Item value="item-3">
                  <Accordion.Trigger
                    expandedIcon={PlusCircleIcon}
                    collapsedIcon={MinusCircleIcon}
                  >
                    <h5 className="text-left text-lg font-medium text-s2-dark-blue">
                      Política de Seguridad Vial
                    </h5>
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <div className="prose text-sm text-s2-gray-600">
                      <p>
                        Para Coninsa, la salud y la seguridad forman parte de la
                        naturaleza misma del trabajo y, por lo tanto, son
                        conceptos inherentes a todos los procesos operativos
                        incluyendo el transporte de las personas, productos y
                        servicios.
                      </p>
                      <p>
                        Como tal, debe formar parte de la planeación y diseño de
                        los trabajos que aquí se realizan, para evitar al máximo
                        situaciones de riesgo que puedan afectar a las personas,
                        equipos e instalaciones. Para tal efecto, la alta
                        gerencia se compromete a dar cumplimiento al cronograma
                        de trabajo y las directrices enmarcadas en el plan
                        estratégico de seguridad vial de nuestra compañía, el
                        cuál será válido en los ejercicios de auditorías
                        internas para garantizar su implementación y mejora
                        continua.
                      </p>
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Root>
            </div>
          </div>
        </Container>
      </Section>

      <Section className="bg-gradient-to-b from-s2-white from-50% to-s2-dark-blue to-50% lg:bg-gradient-to-r lg:from-55% lg:to-45%">
        <Container>
          <div className="grid-cols-12 gap-4 lg:grid">
            <div className="prose col-span-7 text-balance pb-20 text-s2-gray-600 lg:pb-0 lg:text-lg">
              <p>
                Los contratistas, proveedores, contratistas independientes y
                empresas de servicios temporales y demás actores de la cadena de
                abastecimiento, son parte fundamental en la administración y
                funcionamiento de Coninsa.
              </p>
              <p className="font-bold">
                {" "}
                Son un grupo de interés conexo para el cumplimiento de nuestros
                valores, comportamientos, estrategias y metas.
              </p>
            </div>
            <div className="col-span-5 lg:px-8">
              <div className="mb-4 text-balance text-white">
                Con este manual, queremos aportarle beneficios a esa búsqueda
                integral de{" "}
                <span className="font-bold">“Construimos Bienestar”</span>.
              </div>
              <a
                className="flex gap-4 rounded-lg bg-white p-6 text-s2-dark-blue shadow-[0px_3px_6px_#00000029] hover:bg-s2-mint hover:text-white lg:inline-flex"
                href="#"
              >
                <img src={pdfIcon} alt="PDF icon" />
                <div>
                  <h4 className="mb-2 text-balance text-lg !leading-5 lg:text-xl">
                    Manual de
                    <span className="block font-bold">
                      Gestión de Contratistas
                    </span>
                  </h4>
                  <p className="text-s2-gray-600">Archivo PDF - 1200 Kb</p>
                </div>
              </a>
            </div>
          </div>
        </Container>
      </Section>

      <Section tint="ligther-gray">
        <Container className="mb-6">
          <div className="flex grid-cols-3 flex-col gap-4 lg:grid lg:items-center">
            <h2 className="mb-5 text-3xl font-extrabold text-s2-lime lg:mb-0 lg:text-center lg:text-5xl">
              Pilares
              <span className="block font-handwritting text-6xl font-medium text-s2-dark-blue lg:text-7xl">
                Principales
              </span>
            </h2>
            {pillars.map((item) => (
              <div
                key={item.id}
                className="rounded-lg bg-white px-5 py-7 shadow-[0px_3px_6px_#00000029] lg:px-9 lg:py-10"
              >
                <img
                  src={item.icon}
                  alt="Pillar icon"
                  className="mb-2 h-14 w-14 rounded-full bg-white object-contain p-2 shadow-[0px_3px_6px_#00000029] lg:mb-4 lg:h-20 lg:w-20 lg:p-4"
                />
                <div className="mb-5 lg:mb-7">
                  <div className="text-lg text-s2-gray-600 lg:text-xl">
                    {item.tag}
                  </div>
                  <h4 className="text-xl font-bold !leading-4 text-s2-dark-blue lg:text-2xl">
                    {item.title}
                  </h4>
                </div>
                <Button inverted href={item.url}>
                  Más información
                </Button>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section className="relative -mt-16 lg:-mt-20">
        <Container>
          <SpeechBubble
            tint="mint"
            className="mb-12 px-9 py-2 text-xl font-bold lg:px-10 lg:text-2xl"
          >
            Social
          </SpeechBubble>
          <div className="items-center justify-between gap-4 lg:flex">
            <h3 className="font-display text-4xl font-extralight uppercase text-s2-teal lg:text-5xl">
              Prácticas
              <span className="block font-medium text-s2-dark-blue">
                sostenibles
              </span>
            </h3>

            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--lg mt-5"
            >
              {data.map((item) => (
                <SplideSlide key={item.id}>
                  <BubbleCard.Root image={item.image} className="h-64 lg:h-72">
                    <BubbleCard.Content className="min-h-24 bg-s2-teal">
                      <h4 className="text-center text-lg font-bold text-white lg:text-[22px]">
                        {item.title}
                      </h4>
                    </BubbleCard.Content>
                    <BubbleCard.Anchor href={item.url} />
                  </BubbleCard.Root>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>
    </div>
  );
}
